import { Plugin } from '@nuxt/types'
import { onGlobalSetup, useRoute } from '@nuxtjs/composition-api'
import { useUiNotification } from '~/composables'

/**
 * This plugin restore cartId from query params after failed payment
 * Also render ui notification
 * @param app
 */
// eslint-disable-next-line func-style
const plugin: Plugin = ({ app }) => {
  onGlobalSetup(() => {
    const route = useRoute()
    const { send } = useUiNotification()
    const cartId = route.value.query.cartId
    if (!!cartId && typeof cartId === 'string' && cartId.length === 32) {
      app.$vsf.$magento.config.state.setCartId(cartId)

      send({
        type: 'danger',
        title: 'Makse ebaõnnestus, sinu ostukorv on taastatud',
        id: Symbol('Payment failed'),
        timeToLive: 5000
      })
    }
  })
}

// eslint-disable-next-line import/no-default-export
export default plugin
